import _secondsToTime from "./secondsToTime";
var exports = {};
var secondsToTime = _secondsToTime;

exports = function prettyETA(seconds) {
  var time = secondsToTime(seconds); // Only display hours and minutes if they are greater than 0 but always
  // display minutes if hours is being displayed
  // Display a leading zero if the there is a preceding unit: 1m 05s, but 5s

  var hoursStr = time.hours ? time.hours + "h " : "";
  var minutesVal = time.hours ? ("0" + time.minutes).substr(-2) : time.minutes;
  var minutesStr = minutesVal ? minutesVal + "m" : "";
  var secondsVal = minutesVal ? ("0" + time.seconds).substr(-2) : time.seconds;
  var secondsStr = time.hours ? "" : minutesVal ? " " + secondsVal + "s" : secondsVal + "s";
  return "" + hoursStr + minutesStr + secondsStr;
};

export default exports;